import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Contact = () => {
  const lang = "pl"

  return (
    <Layout lang={lang} translation="/contact/" contactFooter>
      <SEO title="Kontakt" />
    </Layout>
  )
}

export default Contact
